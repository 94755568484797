<template>
  <div class="loanDetail">
    <mt-header :title="$t('loanDetails')">
      <router-link v-if="callBack" :to="callBack" slot="left">
        <mt-button icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <router-link v-else to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="tips">{{$t('repaytTips')}} </div>
      <div class="content-cell">
        <mt-cell :title="$t('loanAmount')">
          <div class="mt-cell-text">${{ item.applyAmount | formatMoney }}</div>
        </mt-cell>
        <mt-cell :title="$t('loanTerm')">
          <div class="mt-cell-text">{{ item.displayLoanDays || item.loanTerms }} {{ $t('day') }}</div>
        </mt-cell>
        <div class="nav"></div>
        <mt-cell :title="$t('repaymentAmount')">
          <div class="mt-cell-text">${{ item.repayAmount | formatMoney }}</div>
        </mt-cell>
        <mt-cell :title="$t('transferAmount')">
          <div class="mt-cell-text">${{ item.transferAmount | formatMoney }}</div>
        </mt-cell>
        <div class="nav"></div>
        <mt-cell :title="$t('filingDate')">
          <div class="mt-cell-text">{{ item.applyDate }}</div>
        </mt-cell>
        <mt-cell :title="$t('paymentDate')">
          <div class="mt-cell-text">{{ item.loanDate }}</div>
        </mt-cell>
        <mt-cell :title="$t('repaymentDate')">
          <div class="mt-cell-text">{{ item.repayDate }}</div>
        </mt-cell>
        <div class="nav"></div>
        <mt-cell :title="$t('orderContractNo')">
          <div class="mt-cell-text">{{ item.applyId }}</div>
        </mt-cell>
        <mt-cell :title="$t('contractStatus')">
          <div class="mt-cell-text">{{ item.contractStatusName }}</div>
        </mt-cell>
      </div>
    </div>
    <div v-if="item.contractStatus === 'OVERDUE' || item.contractStatus === 'NORMAL' " class="bottom">
      <mt-button type="primary" size="large" @click="$router.push(`/mine/repaymentInfo?applyId=${contractNo}`)">{{
        $t("repayment")
      }}</mt-button>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { contractDetail } from '../../utils/api'
export default {
  name: 'LoanDetail',
  components: {
    callPhone
  },
  computed: {
    contractNo() {
      return this.$route.query.contractNo
    },
    callBack() {
      return this.$route.query.callBack
    }
  },
  data () {
    return {
      item: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.getContractDetail()
      this.$indicator.close();
      this.$NProgress.done()
    },
    async getContractDetail() {
      await this.$axios({
        method: 'post',
        url: contractDetail,
        params: {
          applyId: this.contractNo
        }
      })
        .then((e) => {
          if (e.status.code === '000') {
            this.item = e.body
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.loanDetail {
  background: url("../../assets/header-bg.png") no-repeat;
  background-size: 100% auto;
  min-height: 500px;
  .content{
    margin: 20px 20px;
    background: linear-gradient(to bottom, #F9F6FF, #E2D6FF);
    border-radius: 20px;
    .tips{
      padding: 20px 20px;
      color: #855EF4;
      text-align: center;
      font-weight: bold;
    }
    .content-cell{
      margin: 0 10px 10px;
    }
    .mt-cell-text{
      color: #855EF4;
      font-weight: Bold;
    }
    .nav{
      margin: 10px 10px 10px;
      border-bottom: 1px solid #FFFFFF;
    }
  }
  /deep/ .mint-cell{
    background-color: transparent;
    min-height: 30px;
  }
  /deep/ .mint-cell-wrapper{
    background: transparent;
  }
  .detail {
    width: 16px;
    margin: 0 5px;
  }
  .stages {
    .item {
      box-shadow: 0px 0px 2px 0px rgba(34, 35, 40, 0.22);
      margin: 10px;
      padding: 10px;
      border-radius: 5px;
      .title {
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 18px;
      }
      /deep/ .mint-cell-value {
        color: brown;
        font-weight: 600;
      }
      .free {
        padding: 5px 10px;
        background: #F15234;
        color: white;
        border-radius: 5px;
        margin-left: 5px;
      }
    }
  }
  .bottom {
    padding: 20px 20px 20px 20px;
  }
}
</style>
